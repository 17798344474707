@import "./reset.scss";
@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&family=Ubuntu:wght@400;700&display=swap");

*,
::before,
::after {
    box-sizing: inherit;
    margin: 0;
}

html {
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    margin: 0;
    width: 100%;
    background: $eerieBlack;
    color: $lightGray;
    font-family: $Ubuntu;
    overflow: hidden;
}

main {
    margin: 0 1.5rem;

    @include breakpoint(xl) {
        margin: 0;
        width: 100%;
    }
}

p {
    font-family: $NanumGothic;
    letter-spacing: 1.2px;
    line-height: 1.6;
    font-size: 1rem;
}
