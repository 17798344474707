// COLORS - https://coolors.co/0b090a-161a1d-660708-a4161a-ba181b-e5383b-b1a7a6-d3d3d3-f5f3f4-ffffff
$richBlack: #0b090a;
$eerieBlack: #161a1d;
$bloodRed: #660708;
// $bloodRed: lightblue;
$rubyRed: #a4161a;
// $rubyRed: lightblue;
$carnelian: #ba181b;
$imperialRed: #e5383b;
$silverChalice: #b1a7a6;
$lightGray: #d3d3d3;
$cultured: #f5f3f4;
$white: #fff;

// FONTS -
$Ubuntu: "Ubuntu", sans-serif;
$NanumGothic: "Nanum Gothic", sans-serif;

// HEIGHTS
$navHeight: 85px;

// OTHER
$borderRadius: 5px;
$transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
$boxShadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba($richBlack, 0.2),
    0 4px 4px rgba($richBlack, 0.15), 0 8px 8px rgba($richBlack, 0.1),
    0 16px 16px rgba($richBlack, 0.05);

// BREAKPOINTS

@mixin breakpoint($point) {
    @if $point == xl {
        // 1120px
        @media (min-width: 70em) {
            @content;
        }
    } @else if $point == lg {
        // 1024px
        @media (min-width: 64em) {
            @content;
        }
    } @else if $point == md {
        // 768px
        @media (min-width: 48em) {
            @content;
        }
    } @else if $point == md-only {
        // 767px
        @media (max-width: 767px) {
            @content;
        }
    } @else if $point == sm {
        // 600px
        @media (min-width: 37.5em) {
            @content;
        }
    } @else if $point == sm-only {
        @media (max-width: 599px) {
            @content;
        }
    }
}
