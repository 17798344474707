@import "./../../styles/variables.scss";

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0.75rem 0.75rem;
    // background: $eerieBlack;
    background: rgba($eerieBlack, 0.8);
    border-radius: $borderRadius;
    color: $lightGray;
    box-shadow: $boxShadow;

    @include breakpoint(sm) {
        padding: 0.75rem 1.25rem;
    }
}
