@import "./../../styles/variables.scss";

.button {
    border: none;
    outline: none;
    padding: 0.75rem 1.25rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $rubyRed;
    border-radius: $borderRadius;
    background: transparent;
    color: $lightGray;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    transition: $transition;

    &:hover,
    &:focus {
        color: $cultured;
        background: rgba($rubyRed, 0.2);
    }

    &:active {
        transform: scale(0.95);
    }
}
