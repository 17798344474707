@import "./../../styles/variables.scss";

.logo {
    letter-spacing: -1px;
    font-size: 2rem;
    font-weight: 800;
    color: $rubyRed;
    text-decoration: none;
    display: inline-block;

    span {
        text-align: center;
        display: block;
        font-size: 14px;
        color: $silverChalice;
        font-weight: 200;
    }
}
