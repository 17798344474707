@import "./../../../styles/variables.scss";

.projects__section-container {
    margin-top: 150px;
    // padding-top: 250px;
}

.projects__list {
    display: flex;
    flex-direction: column;
}
