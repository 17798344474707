@import "./../../styles/variables.scss";

.header__container {
    width: 100%;
    position: fixed;
    background: rgba($eerieBlack, 0.5);
    backdrop-filter: blur(4px);
    filter: none !important;
    pointer-events: auto !important;
    user-select: auto !important;
    z-index: 100;
    display: flex;
    justify-content: center;
}

.header {
    width: 1920px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $navHeight;
    padding: 0 1.5rem;

    @include breakpoint(md) {
        padding: 0 3rem;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__menu-container {
        svg {
            font-size: 2rem;
        }
        @include breakpoint(md) {
            display: none;
        }
    }

    .menu__toggle {
        position: relative;
        z-index: 10;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }
}

.menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    transform: translateX(100vw);
    opacity: 0;
    transition: all 0.3s ease;
    background: rgba($eerieBlack, 0.5);

    &__content {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem 3rem;
        height: 100%;
        background: $eerieBlack;
        width: 400px;
        max-width: 100%;
    }
}

.menu-show {
    transform: translateX(0vw);
    opacity: 1;
}
