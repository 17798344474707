@import "./../../../../styles/variables.scss";

.project {
    &:not(:last-child) {
        margin-bottom: 3rem;
    }
    display: grid;
    position: relative;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    box-shadow: $boxShadow;
    cursor: pointer;

    @include breakpoint(md-only) {
        &:hover .project__content__title a {
            color: $white;
        }
    }

    @include breakpoint(md) {
        cursor: initial;
        box-shadow: none;

        &:not(:last-child) {
            margin-bottom: 5rem;
        }
    }

    &:nth-of-type(2n + 1) .project__content {
        text-align: left;

        @include breakpoint(md) {
            grid-column: 6 / -1;
            text-align: right;

            &__tech {
                justify-content: flex-end;
                margin-left: auto;

                li {
                    &:not(:first-of-type) {
                        margin-left: 1rem;
                    }
                }
            }
            &__links {
                justify-content: flex-end;
            }
        }

        @include breakpoint(lg) {
            grid-column: 7 / -1;
        }
    }

    &:nth-of-type(2n) .project__content {
        @include breakpoint(md) {
            &__tech li {
                &:not(:last-of-type) {
                    margin-right: 1rem;
                }
            }
        }
    }

    &:nth-of-type(2n + 1) .project__image {
        grid-column: 1 / -1;

        @include breakpoint(md) {
            grid-column: 1 / 8;
        }
    }
}

.project__content {
    grid-area: 1 / 1 / -1 / -1;
    padding: 1.2rem;
    z-index: 2;
    position: relative;

    @include breakpoint(sm) {
        padding: 2rem;
    }

    @include breakpoint(md) {
        padding: 0;
        grid-area: 1 / 1 / -1 / 8;
    }

    @include breakpoint(lg) {
        grid-area: 1 / 1 / -1 / 7;
    }

    &__pre {
        text-shadow: 0px 0px 3px rgba($richBlack, 0.8);
        color: $rubyRed;
        font-size: 1.1rem;
        font-weight: 800;
        margin-bottom: 0.6rem;
        @include breakpoint(md) {
            font-size: 1rem;
        }
    }
    &__title {
        a {
            color: $lightGray;
            font-size: 1.6rem;
            text-decoration: none;
            font-weight: 800;
            letter-spacing: 0.9px;
            transition: $transition;

            @include breakpoint(md) {
                font-size: 1.4rem;
                &:hover {
                    color: $white;
                }
            }
        }
        margin-bottom: 1.75rem;
    }
    &__description {
        border: 2px solid $rubyRed;

        padding: 1rem 1.5rem;
        p {
            font-weight: 400;
            font-size: 0.92rem;
        }
        margin-bottom: 1.1rem;
    }
    &__tech {
        display: flex;
        flex-wrap: wrap;
        max-width: 300px;

        li {
            cursor: default;
            color: $lightGray;
            font-size: 1.1rem;

            &:not(:last-of-type) {
                margin-right: 1rem;
            }

            @include breakpoint(md) {
                font-size: 1rem;
                &:not(:last-of-type) {
                    margin-right: 0rem;
                }
            }
        }
        margin-bottom: 1.1rem;
    }
    &__links {
        display: flex;

        a {
            color: $lightGray;
            font-size: 2rem;
            transition: $transition;
            &:not(:last-child) {
                margin-right: 1rem;
            }

            &:hover {
                color: $white;
            }

            @include breakpoint(md) {
                font-size: 1.6rem;
            }
        }
    }
}

.project__image {
    grid-area: 1 / 1 / -1 / -1;
    z-index: 1;
    position: relative;

    @include breakpoint(md) {
        grid-area: 1 / 6 / -1 / -1;
    }

    a {
        width: 100%;
        height: 100%;
        text-decoration: none;

        .img__wrap {
            background: $eerieBlack;
            border-radius: $borderRadius;
            display: flex;

            @include breakpoint(md) {
                background: $bloodRed;
                max-width: 600px;
                &:hover img {
                    opacity: 1;
                }
            }

            img {
                border-radius: $borderRadius;
                opacity: 0.3;
                min-height: 450px;
                width: 100%;
                object-fit: cover;
                transition: $transition;

                @include breakpoint(md) {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
