@import "./../../../styles/variables.scss";

.bgcontrol {
    position: relative;
}

.about__section-BGtop {
    position: absolute;
    top: 0;
    left: -100%;
    width: 300%;
    height: calc(100% + 5rem);
    transform: rotateZ(-5deg) translateY(-5rem);
    background: $bloodRed;
    z-index: -1;
}

.about__section-BGbottom {
    position: absolute;
    bottom: 0;
    right: -100%;
    width: 300%;
    height: calc(100% + 5rem);
    transform: rotateZ(5deg) translateY(5rem);
    background: $bloodRed;
    z-index: -1;
}

.about__section-container {
    position: relative;
}

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
        flex-direction: row;
    }
}

.about {
    margin-bottom: 6rem;
    flex: 1;

    @include breakpoint(md) {
        margin-bottom: 0;
        margin-right: 6rem;
    }
    &__descriptions {
        display: flex;
        flex-direction: column;

        .description-txt {
            &:not(:last-child) {
                margin-bottom: 1.75rem;
            }
            color: $lightGray;
        }
    }
}

.technologies {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1rem;
        column-gap: 0.5rem;

        @include breakpoint(md) {
            grid-template-columns: 1fr;
        }

        @include breakpoint(lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        .technology__card {
            justify-content: space-between;
            user-select: none;
            transition: $transition;

            &:hover {
                box-shadow: 1px 1px 1px 1px rgba($richBlack, 0.3);
            }
            &:hover .technology__img {
                color: $rubyRed;
            }

            .technology__img {
                color: $lightGray;
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                transition: $transition;

                @include breakpoint(sm) {
                    font-size: 2rem;
                }
            }

            .technology__name {
                font-size: 1.1rem;

                @include breakpoint(sm) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    &__header,
    &__footer {
        color: $white;
        font-size: 1.2rem;
        letter-spacing: 1px;
        font-weight: 200;
    }
    &__header {
        margin-bottom: 1.5rem;
    }
    &__footer {
        margin-top: 1.5rem;
        text-align: end;
    }

    .label {
        &__white {
            color: $white;
        }
        &__orange {
            color: $silverChalice;
        }
        &__teal {
            color: rgb(98, 206, 241);
        }
        &__underline {
            text-decoration: underline;
        }
    }

    &__input {
        width: 130px;
        border: none;
        outline: none;
        font-size: 16px;
        color: inherit;
        font-family: inherit;
        padding: 0 4px 2px;
        background: transparent;
        box-shadow: inset 0 -1px 0 0 rgb(98, 206, 241);
        text-align: center;
    }

    .arrow {
        padding: 0 4px;
        font-weight: 400;
        color: $silverChalice;
    }

    .react {
        animation: spin 8s linear infinite;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
