@import "./../../../styles/variables.scss";

.nav {
    display: none;
    align-items: center;

    ul {
        display: flex;
        &:not(:last-child) {
            margin-right: 1.75rem;
        }
        li {
            &:not(:last-child) {
                margin-right: 1.5rem;
            }
            a {
                text-decoration: none;
                color: $lightGray;
                transition: $transition;

                &:hover {
                    color: $cultured;
                }
            }
        }
    }

    @include breakpoint(md) {
        display: flex;
    }
}

.menu__nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.4rem;

    ul {
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
            margin-bottom: 2.25rem;
        }

        li {
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
            a {
                text-decoration: none;
                color: $lightGray;

                &:hover {
                    color: $cultured;
                }
            }
        }
    }
}
