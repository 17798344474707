@import "./../../../styles/variables.scss";

.hero__section-container {
    padding: 0;
    min-height: 900px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 150px 0 150px;

    @include breakpoint(sm) {
        padding: 0;
        padding-top: $navHeight;
        margin-bottom: 100px;
    }
}

.pre-txt {
    color: $rubyRed;
    font-size: 1.1rem;
    font-weight: 800;
    letter-spacing: 1px;
    margin: 0 0 0.9rem 0.25rem;
}

.large-txt {
    font-family: $Ubuntu;
    font-weight: 800;
    font-size: clamp(2.5rem, 8vw, 4.4rem);
    margin: 0 0 0.9rem;

    &-2 {
        color: $silverChalice;
        font-size: clamp(2.5rem, 8vw, 3.8rem);
        margin: 0 0 2rem;
    }
}

.description-txt {
    color: $silverChalice;
    max-width: 550px;
    margin: 0 0 2.4rem;
}

.hero__btn {
    font-size: 1.1rem;
    padding: 1rem 1.75rem;
}
